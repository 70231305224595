<!--
 * @Description: 商品详情
 -->
<template>
  <div class="container">
    <div class="goodsInfo">
      <img :src="goodsDetail.productInfo.image" alt="" />
      <div class="detail">
        <p class="title">{{ goodsDetail.productInfo.storeName }}</p>
        <p class="storeInfo">{{ goodsDetail.productInfo.storeInfo }}</p>
        <div class="price">
          <span style="margin-left: 15px">价格：</span
          ><span
            >{{ goodsDetail.productInfo.price }}/{{
              goodsDetail.productInfo.unitName
            }}</span
          >
        </div>
        <el-button round @click="buy">立即购买</el-button>
      </div>
    </div>
    <div class="goodsDetail">
      <div class="title">产品详情</div>
      <div class="content" v-html="goodsDetail.productInfo.content"></div>
      <!-- <img :src="goodsDetail.productInfo.contents" alt="" /> -->
    </div>
    <!-- 购买 -->
    <el-dialog
      destroy-on-close
      :before-close="closeOrder"
      title="订单购买"
      :visible.sync="dialogVisible"
      append-to-body
      width="1080px"
    >
      <el-table
        :cell-style="cellStyle"
        :header-cell-style="cellStyle"
        :data="orderList"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="storeName"
          show-overflow-tooltip
          label="产品名称"
          width="300"
        >
        </el-table-column>
        <el-table-column prop="cartNum" label="数量"> </el-table-column>
        <el-table-column prop="price" label="价格"> </el-table-column>
        <el-table-column prop="aaa" label="商家优惠券"> </el-table-column>
      </el-table>
      <div class="payBox">
        <div class="price">
          支付方式：<el-button type="danger" round plain>扫码支付</el-button>
          <p>
            实付金额：<span>¥ {{ $route.query.flag == 2 ? '5000.00' : '1.00' }}</span>
          </p>
        </div>
        <img :src="qrcode" alt="" />
        <p class="payType">
          使用 <img src="@/assets/images/icon/wx.png" alt="" /> 微信扫码支付
        </p>
        <!-- <div class="protocol">
          <el-checkbox v-model="checked"
            ><span>《端木购买协议1》</span><span>《端木购买协议2》</span
            ><span>《端木购买协议3》</span></el-checkbox
          >
        </div> -->
      </div>
    </el-dialog>
    <!-- 是否完成支付 -->
    <el-dialog title="温馨提示" :visible.sync="tips" width="600px">
      <div class="tips">
        <p class="title">支付结果确认</p>
        <p>请问您是否已完成支付？</p>
        <el-row :gutter="100">
          <el-col :span="12">
            <el-button plain @click="tipsCancel">未完成</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="warning" @click="compeleted">已完成</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <!-- 感谢购买  -->
    <el-dialog title="感谢购买" :visible.sync="thanks" width="1080px">
      <div class="thanks">
        <img src="@/assets/images/icon/compelete.png" alt="" />
        <p>
          客服人员
          会在1小时内与您取得联系，帮助您搭建网站，您也可以拨打客服电话（10000001）主动获取帮助
        </p>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import qrcode from "qrcode";
export default {
  data() {
    return {
      goodsDetail: {},
      dialogVisible: false,
      tips: false,
      thanks: false,
      fullscreenLoading: false,
      orderList: [],
      orderInfo: {},
      checked: false,
      cellStyle: {
        textAlign: "center",
        color: "#3D3D3D",
        fontSize: "24px",
        fontWeight: "500",
        height: "60px",
        padding: "0",
        // border: "1px solid #000000",
      },
      preOrderNo: "",
      qrcode: "",
      timers: null,
      timer: null,
      times: 0,
    };
  },
  mounted() {
    this.$route.query.goodsId && this.getGoodsDetail(this.$route.query.goodsId);
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.timers);
  },
  methods: {
    getGoodsDetail(id) {
      this.$axios
        .get("/api/front/product/detail/" + id, {})
        .then((res) => {
          // 获取种类的响应
          this.goodsDetail = res.data.data;
          // this.goodsDetail.productInfo.contents =
          //   this.goodsDetail.productInfo.content.slice(
          //     28,
          //     this.goodsDetail.productInfo.content.indexOf("jpg") + 3
          //   );
          console.log("@@获取商品所有种类的响应：", res, this.goodsDetail);
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    buy() {
      const token = sessionStorage.getItem("token");
      console.log(token);
      if (!token) {
        this.$message.warning("请先登录");
        this.$router.push({ path: "/login" });
        return;
      }
      this.$axios
        .post("/api/front/order/realCreateOrder", {
          preOrderType: "buyNow",
          orderDetails: [
            {
              attrValueId: this.goodsDetail.productValue.默认.id,
              productId: this.goodsDetail.productInfo.id,
              productNum: 1,
            },
          ],
        })
        .then((res) => {
          console.log(res.data.data, "res.data.data");
          if (res.data.code == 200) {
            this.preOrderNo = res.data.data.orderNo;
            // this.getOrderInfo();
            this.getQrcode();
            this.timers = setInterval(() => {
              clearInterval(this.timer);
              this.getQrcode();
            }, 180000);
          }
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // 订单信息
    getOrderInfo() {
      this.$axios
        .get("/api/front/order/detail/" + this.preOrderNo, {})
        .then((res) => {
          this.orderList = res.data.data.orderInfoList;
          if (res.data.data.paid) {
            this.closeOrder();
            this.thanks = true;
          }
        });
    },
    // 订单信息
    getQrcode() {
      this.times += 1;
      if (this.times > 5) {
        this.$message.warning("订单超时，请重新下单！");
        clearInterval(this.timer);
        clearInterval(this.timers);
        this.$router.push("/");
        return;
      }
      this.$axios
        .get("/api/front/wxPay/nativePay?orderNo=" + this.preOrderNo, {})
        .then((res) => {
          if (res.data.code == 200) {
            // this.qrcode = new QRCode("qrcode", res.data.data.qrcode);
            const qrCodeData = res.data.data.qrcode; // 替换为你需要生成二维码的数据
            qrcode.toDataURL(qrCodeData, (err, url) => {
              if (err) {
                console.error(err);
              } else {
                this.qrcode = url;
              }
            });
          }
          this.dialogVisible = true;
          this.timer = setInterval(() => {
            this.getOrderInfo();
          }, 2000);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    //
    closeOrder() {
      clearInterval(this.timer);
      clearInterval(this.timers);
      this.dialogVisible = false;
    },
    // w未完成购买
    tipsCancel() {
      this.tips = false;
    },
    // 已完成购买
    compeleted() {
      this.tips = false;
      this.thanks = true;
      // this.$axios
      //   .post("/api/front/order/clickSuccess", { preOrderNo: this.preOrderNo })
      //   .then((res) => {
      //     if (res.data.code == 200) {
      //       this.tips = false;
      //       this.thanks = true;
      //     }
      //   })
      //   .catch((err) => {
      //     return Promise.reject(err);
      //   });
    },
  },
};
</script>
<style lang="scss" scoped>
.goodsInfo {
  width: 100%;
  height: 330px;
  margin-top: 30px;
  img {
    width: 550px;
    height: 330px;
    float: left;
  }
  .detail {
    height: 330px;
    position: relative;
    width: calc(100% - 580px);
    float: right;
    margin-left: 20px;
    .title {
      font-size: 26px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
    .storeInfo {
      font-size: 18px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.65);
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 10px 0 20px;
    }
    p {
      margin: 0;
    }
    .price {
      height: 48px;
      line-height: 48px;
      background-color: rgba(216, 82, 44, 0.1);
      font-size: 26px;
      font-weight: 500;
      span:nth-child(1) {
        width: 60px;
        height: 36px;
        font-weight: 500;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.65);
        font-style: normal;
        text-transform: none;
      }
      span:nth-child(2) {
        font-size: 26px;
        font-weight: 500;
        color: #d8522c;
      }
    }
    .el-button {
      width: 220px;
      height: 50px;
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      border: none;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #d8522c;
      border-radius: 30px 30px 30px 30px;
    }
  }
}
.goodsDetail {
  width: 100%;
  background: #ffffff;
  margin-top: 68px;
  .title {
    width: 100%;
    height: 60px;
    font-size: 26px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 60px;
    text-align: center;
  }
  ::v-deep .wscnph {
    width: 100%;
  }
  img {
    width: 100%;
  }
}

.payBox {
  text-align: center;
  padding: 0 50px;
  margin-top: 30px;
  margin-bottom: 30px;
  .price {
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    p {
      float: right;
      margin: 0;
      span {
        color: rgba(255, 0, 0, 0.851);
      }
    }
  }
  .payType {
    height: 30px;
    line-height: 30px;
    margin: 20px 0 60px 0;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.65);
    img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
    }
  }
  .protocol {
    .el-checkbox__inner {
      width: 20px;
      height: 20px;
    }
    span {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
.tips {
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.45);
  .title {
    font-size: 34px;
    color: rgba(0, 0, 0, 0.85);
    margin: 30px 0 20px 0;
  }
  p {
    margin: 0;
  }
  .el-row {
    margin-top: 60px;
  }
  .el-button {
    width: 230px;
    height: 68px;
    font-size: 30px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.45);
  }
}
.thanks {
  text-align: center;
  img {
    width: 100px;
    height: 100px;
  }
  p {
    margin: 50px auto;
    width: 863px;
    font-size: 30px;
    font-weight: 500;
    line-height: 52px;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
